import React from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";

import { confirm } from "../../../api/platform";

export function UsageDialog() {
  const [open, setOpen] = React.useState(true);

  // Prevent dialog closing unless agreed.
  const handleClose = () => {};

  const handleOK = async () => {
    confirm();
    setOpen(false);
  };

  const terms_url =
    "https://3013042.fs1.hubspotusercontent-na1.net/hubfs/3013042/assets/2023/Platform%20-%20Dealer%20Terms%20of%20Access%20FINAL%2003%20AUGUST%202023.pdf";

  const italicText = (text: string) => {
    return (
      <Box component="span" sx={{ fontStyle: "italic" }}>
        {text}
      </Box>
    );
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle>Access and Usage Acceptance</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            By accessing {italicText("this Platform")}, {italicText("you")} (on
            behalf of your organisation) agree to the access and use terms
            available{" "}
            <a href={`${terms_url}`} target="_blank" rel="noreferrer">
              here
            </a>{" "}
            , which form an Addendum to {italicText("your")} existing Dealer
            Agreement with Giacom. If {italicText("you")} do not agree to these
            terms, {italicText("you")} must not access or use the{" "}
            {italicText("Vodafone Platform")}. If {italicText("you")} have any
            queries regarding these terms, please contact{" "}
            <a
              href="mailto:distribution.sales@giacom.com"
              target="_blank"
              rel="noreferrer"
            >
              distribution.sales@giacom.com
            </a>
            .
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOK} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
