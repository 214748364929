import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  info: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    whiteSpace: "pre-wrap",
  },
}));

export const CoverageChecker = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.content} spacing={2}>
      <Grid item sm={12}>
        <Box>
          <Card>
            <iframe
              title="Network Status Checker"
              src="https://maps.vodafone.co.uk/coveragecheckers/web/default.aspx?configuration=partner"
              width="100%"
              allow="geolocation"
              height="830px"
              id="vf-status-iframe"
            />
          </Card>
        </Box>
      </Grid>
      <Grid item sm={12}>
        <Box>
          <Typography className={classes.info} variant="body1">
            {"You can subscribe to network outages through the "}
            <a
              href="https://www.vodafone.co.uk/network/status-checker"
              target="_blank"
              rel="noreferrer"
            >
              Vodafone Website
            </a>
            .
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
