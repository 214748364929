import React from "react";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableMeta,
  MUIDataTableOptions,
} from "mui-datatables";
import {
  AppSubdomains,
  getPathForCrossAppRedirect,
} from "@akj-dev/affinity-platform";
import { useGlobalSearch } from "../../../utils/hooks";

type Props = {
  options: MUIDataTableOptions;
};

export default function CustomersTable({ options }: Props) {
  const [customersQuery, setQuery] = useQueryParams({
    search: withDefault(StringParam, ""),
    "customers._limit": withDefault(NumberParam, 10),
    "customers._page": withDefault(NumberParam, 1),
  });

  const searchResult = useGlobalSearch(customersQuery);

  const columns: MUIDataTableColumn[] = [
    {
      name: "name",
      label: "Company Name",
    },
    {
      name: "thirdPartyBillingRef",
      label: "Account Number",
    },
    {
      name: "primaryContact.phone.work",
      label: "Contact Number",
    },
    {
      name: "postcode",
      label: "Postcode",
    },
    {
      name: "primaryContact.name",
      label: "Primary Contact",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          const row =
            searchResult?.data?.customers?.results[tableMeta.rowIndex];
          return (
            <Button
              size="small"
              color="primary"
              href={getPathForCrossAppRedirect(
                AppSubdomains.CUSTOMERS,
                `/customers/${row.accountNumber}`
              )}
            >
              View
            </Button>
          );
        },
      },
    },
  ];

  const customersOptions = {
    ...options,
    count: searchResult?.data?.customers?.meta?.totalCount || 0,
    page: customersQuery["customers._page"] - 1,
    rowsPerPage: customersQuery["customers._limit"],
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({
            "customers._page": tableState.page + 1,
          });
        case "changeRowsPerPage":
          return setQuery({
            "customers._limit": tableState.rowsPerPage,
            "customers._page": 1,
          });
        default:
          break;
      }
    },
  };

  return (
    <MUIDataTable
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">Customers Results</Typography>
          </Box>
          {searchResult.isLoading && <CircularProgress size={24} />}
        </Box>
      }
      data={searchResult?.data?.customers?.results}
      columns={columns}
      options={customersOptions}
    />
  );
}
