import React from "react";
import {
  useQueryParams,
  withDefault,
  StringParam,
  NumberParam,
} from "use-query-params";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableMeta,
  MUIDataTableOptions,
} from "mui-datatables";
import {
  AppSubdomains,
  getPathForCrossAppRedirect,
} from "@akj-dev/affinity-platform";
import { formattedTimestamp } from "../../../utils/formattedTimestamp";
import { useGlobalSearch } from "../../../utils/hooks";

type Props = {
  options: MUIDataTableOptions;
};

export default function SalesTable({ options }: Props) {
  const [salesQuery, setQuery] = useQueryParams({
    search: withDefault(StringParam, ""),
    "sales._limit": withDefault(NumberParam, 10),
    "sales._page": withDefault(NumberParam, 1),
  });

  const searchResult = useGlobalSearch(salesQuery);

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "opportunityName",
      label: "Opp Name",
    },
    {
      name: "companyName",
      label: "Company Name",
    },
    {
      name: "createdAtTimestamp",
      label: "Date & Time",
      options: {
        customBodyRender: (value: number) => {
          return formattedTimestamp(value);
        },
      },
    },
    {
      name: "createdByName",
      label: "Created By",
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          const row = searchResult?.data?.sales?.results[
            tableMeta.rowIndex
          ] as { id: number };
          return (
            <Button
              size="small"
              color="primary"
              href={getPathForCrossAppRedirect(
                AppSubdomains.SALES,
                `/sales/new/cpq?order_id=${row.id}`
              )}
            >
              View
            </Button>
          );
        },
      },
    },
  ];

  const salesOptions = {
    ...options,
    count: searchResult?.data?.sales?.meta?.totalCount || 0,
    page: salesQuery["sales._page"] - 1,
    rowsPerPage: salesQuery["sales._limit"],
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({
            "sales._page": tableState.page + 1,
          });
        case "changeRowsPerPage":
          return setQuery({
            "sales._limit": tableState.rowsPerPage,
            "sales._page": 1,
          });
        default:
          break;
      }
    },
  };

  return (
    <MUIDataTable
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">Sales Results</Typography>
          </Box>
          {searchResult.isLoading && <CircularProgress size={24} />}
        </Box>
      }
      data={searchResult?.data?.sales?.results}
      columns={columns}
      options={salesOptions}
    />
  );
}
