import React from "react";
import {
  useQueryParams,
  withDefault,
  StringParam,
  NumberParam,
} from "use-query-params";
import { Box, CircularProgress, Typography } from "@mui/material";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from "mui-datatables";
import { useGlobalSearch } from "../../../utils/hooks";

type Props = {
  options: MUIDataTableOptions;
};

export default function BillingTable({ options }: Props) {
  const [billingQuery, setQuery] = useQueryParams({
    search: withDefault(StringParam, ""),
    "billing._limit": withDefault(NumberParam, 10),
    "billing._page": withDefault(NumberParam, 1),
  });

  const searchResult = useGlobalSearch(billingQuery);

  const columns: MUIDataTableColumn[] = [
    {
      name: "invoiceNumber",
      label: "Invoice Number",
    },
    {
      name: "date",
      label: "Invoice Date",
    },
    {
      name: "total",
      label: "InvoiceTotal",
    },
    {
      name: "account.name",
      label: "Company Name",
    },
    {
      name: "account.id",
      label: "Account Number",
    },
  ];

  const billingOptions = {
    ...options,
    count: searchResult?.data?.billing?.meta?.totalCount || 0,
    page: billingQuery["billing._page"] - 1,
    rowsPerPage: billingQuery["billing._limit"],
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({
            "billing._page": tableState.page + 1,
          });
        case "changeRowsPerPage":
          return setQuery({
            "billing._limit": tableState.rowsPerPage,
            "billing._page": 1,
          });
        default:
          break;
      }
    },
  };

  return (
    <MUIDataTable
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">Results</Typography>
          </Box>
          {searchResult.isLoading && <CircularProgress size={24} />}
        </Box>
      }
      data={searchResult?.data?.billing?.results}
      columns={columns}
      options={billingOptions}
    />
  );
}
