import React from "react";
import { CircularProgress } from "@mui/material";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { Alert, BrandedDocumentTitle, Main } from "@akj-dev/shared-components";
import SearchTabs from "./components/SearchTabs";
import { useGlobalSearch } from "../../utils/hooks";

export default function GlobalSearchScreen() {
  const [search] = useQueryParam("search", withDefault(StringParam, ""));
  const searchResult = useGlobalSearch({ search });

  if (searchResult.error) {
    if (searchResult.error instanceof Error) {
      return <Alert message={searchResult.error.message} type="error" />;
    }
  }

  if (searchResult.isLoading || !searchResult.isFetchedAfterMount) {
    return <CircularProgress style={{ marginTop: 20 }} />;
  }

  return (
    <>
      <BrandedDocumentTitle title="Search" />
      <Main
        title={
          (searchResult.data?.meta?.totalCount || 0) +
          " results" +
          (search ? " for '" + search + "'" : "")
        }
      >
        <SearchTabs searchResult={searchResult} />
      </Main>
    </>
  );
}
