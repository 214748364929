import React from "react";
import { useLocation } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  ProductContainer,
  ViewContainer,
  ContentContainer,
  SwitchRoutes,
  SubNav,
  StandaloneAppWrapper,
  BrandedDocumentTitle,
} from "@akj-dev/shared-components";
import { routes, subNavRoutes } from "./routes";

const AppStandaloneComponent = () => {
  const { pathname } = useLocation();

  return (
    <ProductContainer>
      <BrandedDocumentTitle title="Home" />
      {pathname.includes(routes.rootPath) && (
        <SubNav routes={subNavRoutes} rootTitle="Home" />
      )}
      <ViewContainer>
        <ContentContainer>
          <SwitchRoutes routes={routes} />
        </ContentContainer>
      </ViewContainer>
    </ProductContainer>
  );
};

export const AppStandalone = () => (
  <StandaloneAppWrapper root={routes.rootPath}>
    <ReactQueryDevtools initialIsOpen={false} />
    <AppStandaloneComponent />
  </StandaloneAppWrapper>
);
