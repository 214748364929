import { useQuery } from "react-query";
import { Query } from "./types";
import { search } from "../api/globalSearch";

export function useGlobalSearch(
  query: Partial<Query>,
  options = { staleTime: 1000 * 60 * 30 }
) {
  return useQuery(["search", query], () => search(query), options);
}
