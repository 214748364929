import React from "react";
import { Typography } from "@mui/material";

interface TabLabelProps {
  label: string;
  numResults: number;
}

export default function TabLabel({ label, numResults }: TabLabelProps) {
  return (
    <>
      <Typography variant="body1">{label}</Typography>
      <Typography variant="caption">{numResults} Results</Typography>
    </>
  );
}
