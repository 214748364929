import React from "react";
import {
  useQueryParams,
  withDefault,
  StringParam,
  NumberParam,
} from "use-query-params";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableMeta,
  MUIDataTableOptions,
} from "mui-datatables";
import {
  AppSubdomains,
  getPathForCrossAppRedirect,
} from "@akj-dev/affinity-platform";
import { formattedTimestamp } from "../../../utils/formattedTimestamp";
import { useGlobalSearch } from "../../../utils/hooks";

type Props = {
  options: MUIDataTableOptions;
};

export default function OrdersTable({ options }: Props) {
  const [ordersQuery, setQuery] = useQueryParams({
    search: withDefault(StringParam, ""),
    "orders._limit": withDefault(NumberParam, 10),
    "orders._page": withDefault(NumberParam, 1),
  });

  const searchResult = useGlobalSearch(ordersQuery);

  const columns: MUIDataTableColumn[] = [
    {
      name: "orderId",
      label: "Order",
    },
    {
      name: "accountName",
      label: "Company Name",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "createdAtTimestamp",
      label: "Created",
      options: {
        customBodyRender: (value: number) => {
          return formattedTimestamp(value);
        },
      },
    },
    {
      name: "submittedAtTimestamp",
      label: "Submitted",
      options: {
        customBodyRender: (value: number) => {
          return formattedTimestamp(value);
        },
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          const row = searchResult?.data?.orders?.results[
            tableMeta.rowIndex
          ] as { id: number };
          return (
            <Button
              size="small"
              color="primary"
              href={getPathForCrossAppRedirect(
                AppSubdomains.ORDERS,
                `/orders/status?view=detail&id=${row.id}`
              )}
            >
              View
            </Button>
          );
        },
      },
    },
  ];

  const ordersOptions = {
    ...options,
    count: searchResult?.data?.orders?.meta?.totalCount || 0,
    page: ordersQuery["orders._page"] - 1,
    rowsPerPage: ordersQuery["orders._limit"],
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({
            "orders._page": tableState.page + 1,
          });
        case "changeRowsPerPage":
          return setQuery({
            "orders._limit": tableState.rowsPerPage,
            "orders._page": 1,
          });
        default:
          break;
      }
    },
  };

  return (
    <MUIDataTable
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">Orders Results</Typography>
          </Box>
          {searchResult.isLoading && <CircularProgress size={24} />}
        </Box>
      }
      data={searchResult?.data?.orders?.results}
      columns={columns}
      options={ordersOptions}
    />
  );
}
