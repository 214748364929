import React from "react";

import PropTypes from "prop-types";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import {
  AppSubdomains,
  getPathForCrossAppRedirect,
} from "@akj-dev/affinity-platform";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    paddingTop: 0,
    minHeight: 100,
  },
  actions: {
    padding: 16,
    marginTop: "auto",
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

interface IHomeScreenCard {
  title: string;
  description: string;
  link: string;
  subDomain: AppSubdomains;
}

export const HomeScreenCard = ({
  title,
  description,
  link,
  subDomain,
}: IHomeScreenCard) => {
  const classes = useStyles();

  const onGoToClick = () => {
    window.location.href = getPathForCrossAppRedirect(subDomain, link);
  };

  return (
    <Card raised className={classes.card}>
      <CardHeader title={title} />
      <CardContent className={classes.content}>
        <Typography variant="body2">{description}</Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          className={classes.label}
          onClick={onGoToClick}
        >
          Go to {title}
        </Button>
      </CardActions>
    </Card>
  );
};

HomeScreenCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  subDomain: PropTypes.string.isRequired,
};
