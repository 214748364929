import React from "react";
import { createRoutingObject } from "@akj-dev/shared-components";
import { HomeScreen } from "./screens/Home/HomeScreen";
import GlobalSearchScreen from "./screens/GlobalSearch/GlobalSearchScreen";

const routingData = [
  {
    path: "search",
    title: "Search",
    subject: <GlobalSearchScreen />,
  },
  {
    path: "/",
    title: "Home",
    subject: <HomeScreen />,
  },
];

export const routes = createRoutingObject(routingData, "/");

export const subNavRoutes = createRoutingObject(
  routingData.filter((route) => route.path === "/"),
  "/"
);
