import React, { SyntheticEvent, useState } from "react";
import { useAuth } from "@akj-dev/affinity-platform";
import { MUIDataTableOptions } from "mui-datatables";
import { Tabs, Tab, Box } from "@mui/material";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ReceiptIcon from "@mui/icons-material/Receipt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import CustomersTable from "./CustomersTable";
import BillingTable from "./BillingTable";
import OrdersTable from "./OrdersTable";
import SupportTable from "./SupportTable";
import SalesTable from "./SalesTable";
import TabPanel from "./TabPanel";
import TabLabel from "./TabLabel";

interface SearchTabsProps {
  searchResult?: any;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabsMap = {
  customers: { value: 0, color: "#271D3E" },
  sales: { value: 1, color: "#56B9F1" },
  orders: { value: 2, color: "#70D0C0" },
  billing: { value: 3, color: "#84E396" },
  support: { value: 4, color: "#FFD183" },
};

export default function SearchTabs({ searchResult }: SearchTabsProps) {
  const { accountSettings } = useAuth();
  const [value, setValue] = useState(0);
  const { data } = searchResult;

  const handleTabChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue);
  };
  const selectedTab = Object.values(tabsMap).filter(
    (t) => t.value === value
  )?.[0];

  const options: MUIDataTableOptions = {
    download: false,
    filter: false,
    print: false,
    rowHover: true,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    elevation: 1,
    disableToolbarSelect: true,
    serverSide: true,
    enableNestedDataAccess: ".",
    sort: false, // todo / sorting disabled until BE ready
  };

  return (
    <Box width="100%" marginBottom={10} marginLeft="auto" marginRight="auto">
      <Box borderBottom={2} borderColor="divider">
        <Tabs
          value={value}
          onChange={handleTabChange}
          sx={{ marginBottom: 2 }}
          textColor="inherit"
          TabIndicatorProps={{
            sx: { height: 4, backgroundColor: selectedTab.color },
          }}
        >
          {accountSettings.can_use_affinity_customers === "1" && (
            <Tab
              value={tabsMap.customers.value}
              disableTouchRipple
              icon={
                <PeopleOutlineIcon
                  fontSize="large"
                  sx={{ color: tabsMap.customers.color }}
                />
              }
              label={
                <TabLabel
                  label="Customers"
                  numResults={data.customers?.meta?.totalCount || 0}
                />
              }
              {...a11yProps(tabsMap.customers.value)}
              sx={{
                minWidth: "20%",
                opacity: data.customers?.meta?.totalCount > 0 ? "100%" : "60%",
              }}
              disabled={parseInt(data.customers?.meta?.totalCount) === 0}
            />
          )}
          {accountSettings.can_use_affinity_sales === "1" && (
            <Tab
              value={tabsMap.sales.value}
              disableTouchRipple
              icon={
                <TrendingUpIcon
                  fontSize="large"
                  sx={{ color: tabsMap.sales.color }}
                />
              }
              label={
                <TabLabel
                  label="Sales"
                  numResults={data.sales?.meta?.totalCount || 0}
                />
              }
              {...a11yProps(tabsMap.sales.value)}
              sx={{
                minWidth: "20%",
                opacity: data.sales?.meta?.totalCount > 0 ? "100%" : "60%",
              }}
              disabled={parseInt(data.sales?.meta?.totalCount) === 0}
            />
          )}
          {accountSettings.can_use_affinity_orders === "1" && (
            <Tab
              value={tabsMap.orders.value}
              disableTouchRipple
              icon={
                <ReceiptIcon
                  fontSize="large"
                  sx={{ color: tabsMap.orders.color }}
                />
              }
              label={
                <TabLabel
                  label="Orders"
                  numResults={data.orders?.meta?.totalCount || 0}
                />
              }
              {...a11yProps(tabsMap.orders.value)}
              sx={{
                minWidth: "20%",
                opacity: data.orders?.meta?.totalCount > 0 ? "100%" : "60%",
              }}
              disabled={parseInt(data.orders?.meta?.totalCount) === 0}
            />
          )}
          {accountSettings.can_use_affinity_billing === "1" && (
            <Tab
              value={tabsMap.billing.value}
              disableTouchRipple
              icon={
                <DescriptionIcon
                  fontSize="large"
                  sx={{ color: tabsMap.billing.color }}
                />
              }
              label={
                <TabLabel
                  label="Billing"
                  numResults={data.billing?.meta?.totalCount || 0}
                />
              }
              {...a11yProps(tabsMap.billing.value)}
              sx={{
                minWidth: "20%",
                opacity: data.billing?.meta?.totalCount > 0 ? "100%" : "60%",
              }}
              disabled={parseInt(data.billing?.meta?.totalCount) === 0}
            />
          )}
          {accountSettings.can_use_affinity_support === "1" && (
            <Tab
              value={tabsMap.support.value}
              disableTouchRipple
              icon={
                <HelpOutlineIcon
                  fontSize="large"
                  sx={{ color: tabsMap.support.color }}
                />
              }
              label={
                <TabLabel
                  label="Support"
                  numResults={data.support?.meta?.totalCount || 0}
                />
              }
              {...a11yProps(tabsMap.support.value)}
              sx={{
                minWidth: "20%",
                opacity: data.support?.meta?.totalCount > 0 ? "100%" : "60%",
              }}
              disabled={parseInt(data.support?.meta?.totalCount) === 0}
            />
          )}
        </Tabs>
        {accountSettings.can_use_affinity_customers === "1" && (
          <TabPanel value={value} index={tabsMap.customers.value}>
            <CustomersTable options={options} />
          </TabPanel>
        )}
        {accountSettings.can_use_affinity_sales === "1" && (
          <TabPanel value={value} index={tabsMap.sales.value}>
            <SalesTable options={options} />
          </TabPanel>
        )}
        {accountSettings.can_use_affinity_orders === "1" && (
          <TabPanel value={value} index={tabsMap.orders.value}>
            <OrdersTable options={options} />
          </TabPanel>
        )}
        {accountSettings.can_use_affinity_billing === "1" && (
          <TabPanel value={value} index={tabsMap.billing.value}>
            <BillingTable options={options} />
          </TabPanel>
        )}
        {accountSettings.can_use_affinity_support === "1" && (
          <TabPanel value={value} index={tabsMap.support.value}>
            <SupportTable options={options} />
          </TabPanel>
        )}
      </Box>
    </Box>
  );
}
