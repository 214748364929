import React from "react";
import {
  useQueryParams,
  withDefault,
  StringParam,
  NumberParam,
} from "use-query-params";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableMeta,
  MUIDataTableOptions,
} from "mui-datatables";
import {
  AppSubdomains,
  getPathForCrossAppRedirect,
} from "@akj-dev/affinity-platform";
import { useGlobalSearch } from "../../../utils/hooks";

type Props = {
  options: MUIDataTableOptions;
};

export default function SupportTable({ options }: Props) {
  const [supportQuery, setQuery] = useQueryParams({
    search: withDefault(StringParam, ""),
    "support._limit": withDefault(NumberParam, 10),
    "support._page": withDefault(NumberParam, 1),
  });

  const searchResult = useGlobalSearch(supportQuery);

  const columns: MUIDataTableColumn[] = [
    {
      name: "ticketNumber",
      label: "Ticket ID",
    },
    {
      name: "account.name",
      label: "Company Name",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "type",
      label: "Type",
    },
    {
      name: "category",
      label: "Category",
    },
    {
      name: "product.type",
      label: "Product",
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          const row = searchResult?.data?.support?.results[
            tableMeta.rowIndex
          ] as { ticketNumber: number };
          return (
            <Button
              size="small"
              color="primary"
              href={getPathForCrossAppRedirect(
                AppSubdomains.SUPPORT,
                `/support/tickets/${row.ticketNumber}`
              )}
            >
              View
            </Button>
          );
        },
      },
    },
  ];

  const supportOptions = {
    ...options,
    count: searchResult?.data?.support?.meta?.totalCount || 0,
    page: supportQuery["support._page"] - 1,
    rowsPerPage: supportQuery["support._limit"],
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({
            "support._page": tableState.page + 1,
          });
        case "changeRowsPerPage":
          return setQuery({
            "support._limit": tableState.rowsPerPage,
            "support._page": 1,
          });
        default:
          break;
      }
    },
  };

  return (
    <MUIDataTable
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">Support Results</Typography>
          </Box>
          {searchResult.isLoading && <CircularProgress size={24} />}
        </Box>
      }
      data={searchResult?.data?.support?.results}
      columns={columns}
      options={supportOptions}
    />
  );
}
