import React from "react";
import { Grid } from "@mui/material";
import { AppSubdomains, useAuth } from "@akj-dev/affinity-platform";
import {
  BrandedDocumentTitle,
  getBrandMeta,
  Main,
} from "@akj-dev/shared-components";
import { HomeScreenCard } from "./components/HomeScreenCard";
import { CoverageChecker } from "./components/CoverageChecker";
import { UsageDialog } from "./components/UsageDialog";

interface HomeScreenProps {}

export const HomeScreen: React.FC<HomeScreenProps> = () => {
  const brand = getBrandMeta();
  const title = "Welcome to " + brand.name;
  const { accountSettings, userSettings } = useAuth();

  return (
    <>
      <BrandedDocumentTitle title="Home" />
      <Main title={title}>
        <Grid container spacing={2}>
          {accountSettings.can_use_affinity_customers === "1" && (
            <Grid item lg={2} md={4} sm={6}>
              <HomeScreenCard
                title="Customers"
                description="View your customers and manage their mobile connections."
                link="/customers"
                subDomain={AppSubdomains.CUSTOMERS}
              />
            </Grid>
          )}
          {accountSettings.can_use_affinity_sales === "1" && (
            <Grid item lg={2} md={4} sm={6}>
              <HomeScreenCard
                title="Sales"
                description="CPQ tool for quick and accurate quoting, contracting and provisioning of orders."
                link="/sales"
                subDomain={AppSubdomains.SALES}
              />
            </Grid>
          )}
          {accountSettings.can_use_affinity_orders === "1" && (
            <Grid item lg={2} md={4} sm={6}>
              <HomeScreenCard
                title="Orders"
                description="View and track the progress of your inflight orders."
                link="/orders"
                subDomain={AppSubdomains.ORDERS}
              />
            </Grid>
          )}
          {accountSettings.can_use_affinity_billing === "1" && (
            <Grid item lg={2} md={4} sm={6}>
              <HomeScreenCard
                title="Billing"
                description="Download invoices and generate billing data reports for your customers."
                link="/billing"
                subDomain={AppSubdomains.BILLING}
              />
            </Grid>
          )}
          {accountSettings.can_use_affinity_insight === "1" && (
            <Grid item lg={2} md={4} sm={6}>
              <HomeScreenCard
                title="Insight"
                description="Powerful analytics to power data-based business decisions."
                link="/insight"
                subDomain={AppSubdomains.INSIGHT}
              />
            </Grid>
          )}
          {accountSettings.can_use_affinity_hub === "1" && (
            <Grid item lg={2} md={4} sm={6}>
              <HomeScreenCard
                title="Hub"
                description="Suite of applications giving customers access to their accounts 24/7."
                link="/hub"
                subDomain={AppSubdomains.HUB}
              />
            </Grid>
          )}
          {(accountSettings.can_use_affinity_support === "1" ||
            accountSettings.can_use_affinity_support_new === "1") && (
            <Grid item lg={2} md={4} sm={6}>
              <HomeScreenCard
                title="Support"
                description="Create, track and manage tickets with the Giacom Service Delivery Team."
                link="/support"
                subDomain={AppSubdomains.SUPPORT}
              />
            </Grid>
          )}
        </Grid>
        {accountSettings.can_access_vf_direct && <CoverageChecker />}
        {accountSettings.can_access_vf_direct &&
          userSettings &&
          !userSettings.platform_terms_and_conditions_confirmed && (
            <UsageDialog />
          )}
      </Main>
    </>
  );
};
